<template>
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub join">
			<!-- contact -->
			<section class="content">
				<div class="centered centered--large">
					<div class="centered__wrap">
						<div class="textbox">
							<div class="complete__icon">
								<i class="icon icon-complete"></i>
							</div>
							<h3 class="textbox__title">
								{{t('10108')}}
							</h3>
							<!-- <p class="textbox__description">
								<strong class="black">{{userData.nick_nm}}</strong>님께 드릴 <br>
								특별한 할인 쿠폰을 준비했어요!
							</p> -->
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered" style="margin-bottom:-10px;padding-bottom:0">
			<router-link to="/rooms" custom v-slot="{ navigate }">
				<button type="button" class="btn btn-primary purple" @click="navigate">{{t('10109')}}</button>
			</router-link>
		</div>
		<div class="btns btns--centered">
			<router-link to="/favorite/step1" custom v-slot="{ navigate }">
				<!-- <button type="button" class="btn btn-confirm" @click="navigate">여행 취향 입력하고 혜택 받기</button> -->
				<button type="button" class="btn btn-confirm" @click="navigate">{{t('10110')}}</button>
			</router-link>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>


<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
	setup() {
		const { t }= useI18n() //번역필수 모듈
		const store = useStore();
		return {
			userData: computed(() => store.state.userData),
			t,
			i18n,
		}
	},
}
</script>